import React, { useLayoutEffect, useState } from 'react'
import { FiAtSign, FiEye, FiEyeOff, FiLock } from 'react-icons/fi'

const LoginForm = ({ history }) => {
  const [values, setValues] = useState({ email: '', password: '' })
  const [isBusy, setIsBusy] = useState(false)
  const [isHidden, setIsHidden] = useState(true)

  const onInputChange = e =>
    setValues(v => ({ ...v, [e.target.name]: e.target.value }))

  const onLogin = e => {
    e.preventDefault()
    setIsBusy(b => !b)
    localStorage.setItem('user', values.email || 'user')
    setTimeout(() => {
      history.push('/dashboard')
    }, 1000)
  }

  return (
    <form onSubmit={onLogin}>
      <div className='l-input-holder'>
        <input
          name='email'
          onChange={onInputChange}
          disabled={isBusy}
          type='email'
          placeholder='name@email.com'
        />
        <label>E-mail</label>
        <span className='inp-icon inp-icon--prefix'>
          <FiAtSign />
        </span>
      </div>
      <div className='l-input-holder'>
        <input
          name='password'
          onChange={onInputChange}
          disabled={isBusy}
          type={isHidden ? 'password' : 'text'}
          placeholder='Minimun 6 characters'
        />
        <label>Password</label>
        <span className='inp-icon inp-icon--prefix'>
          <FiLock />
        </span>
        {values.password && (
          <span
            className='inp-icon inp-icon--suffix'
            onClick={() => setIsHidden(!isHidden)}>
            {isHidden ? <FiEye /> : <FiEyeOff />}
          </span>
        )}
      </div>
      <button disabled={isBusy} className='biz-login-btn' type='submit'>
        {isBusy ? <span className='biz-loader' /> : 'Login'}
      </button>
    </form>
  )
}

export default function Login(props) {
  useLayoutEffect(() => {
    if (localStorage.getItem('user')) props.history.push('/dashboard')
  }, [props.history])
  return (
    <div className='biz-login'>
      <div />
      <section className='login-section'>
        <div className='login-card'>
          <p className='l-title'>
            ビズマッチ
            <span>Biz Match</span>
          </p>
          <div>
            <LoginForm {...props} />
            <a href='/login' className='f-pass'>
              Forgot Password?
            </a>
            <span className='new-acc'>
              Don't have an account?<a href='/login'>Register</a>
            </span>
          </div>
        </div>
      </section>
    </div>
  )
}
