import gsap from 'gsap/all'
import moment from 'moment'
import 'moment-timezone'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  FiArrowLeft,
  FiArrowRight,
  FiBell,
  FiCalendar,
  FiCheckCircle,
  FiExternalLink,
  FiLayers,
  FiLogOut,
  FiMapPin,
  FiMessageSquare,
  FiPhone,
  FiPhoneCall,
  FiPlus,
  FiRss,
  FiSend,
  FiSettings,
  FiUser,
} from 'react-icons/fi'
import businessMeetings from '../components/businessMeetings.json'

let prevScroll = 0,
  isHeaderHidden = false

const clocks = [
  { of: 'IN', timezone: 'Asia/Kolkata' },
  { of: 'JP', timezone: 'Asia/Tokyo' },
]
const events = [
  {
    date: '8月27日(火)',
    content: 'インドファッションフェア ２０２１セミナー',
  },
  {
    date: '8月28日(水)',
    content: 'インドファッションフェア ２０２１',
  },
  {
    date: '8月30日(金)',
    content: 'インドファッションフェア ２０２１',
  },
  {
    date: '9月21日(木)-24日(日)',
    content: 'インドファッションフェア ２０２１展望セミナー',
  },
]
const announcements = [
  'インドファッションフェア ２０２１イベントスケジュール発表',
  'インドファッションフェア ２０２１出展者リスト更新',
  'インドト物造り２０２１ 出展者リスト更新',
]

const Clock = ({ timezone }) => {
  const [time, setTime] = useState()
  const timer = useRef(null)
  const updateTime = () =>
    setTime(moment.tz(timezone).format('DD MMM YYYY,HH:mm:ss'))
  useEffect(() => {
    updateTime()
    if (!timer.current) timer.current = setInterval(updateTime, 1000)
    return () => {
      if (timer.current) clearInterval(timer.current)
    }
  }, [timezone])
  return time ? time.split(',').map(t => <span>{t}</span>) : null
}
const BizHeading = ({ title, icon, subtitle }) => {
  return (
    <div className='biz-heading'>
      <p>
        {icon}
        <div>
          {title}
          <span>{subtitle}</span>
        </div>
      </p>
    </div>
  )
}

const SuggestionsList = ({ list }) => {
  const suggestionScrollRef = useRef(null)
  return (
    <div className='biz-suggestions'>
      <div className='nav-arrows'>
        <button
          onClick={() => {
            if (suggestionScrollRef.current)
              suggestionScrollRef.current.scrollBy({
                left: -400,
                behavior: 'smooth',
              })
          }}>
          <FiArrowLeft />
        </button>
        <button
          onClick={() => {
            if (suggestionScrollRef.current)
              suggestionScrollRef.current.scrollBy({
                left: 400,
                behavior: 'smooth',
              })
          }}>
          <FiArrowRight />
        </button>
      </div>
      <ul ref={suggestionScrollRef}>
        {list.map(m => (
          <li className='suggestion-card'>
            <div className='suggestion-card--head'>
              <span>{m.business_name[0]}</span>
              <ul>
                <li>
                  <FiUser />
                </li>
                <li>
                  <a target='_blank' href={m.business_url}>
                    <FiExternalLink />
                  </a>
                </li>
                <li>
                  <FiMessageSquare />
                </li>
              </ul>
            </div>
            <div className='suggestion-card--body'>
              <p>
                {m.business_name}
                {/* <span>{m.industry}</span> */}
              </p>
              <span>
                <FiMapPin /> {m.hq}
              </span>
            </div>
            <div className='suggestion-card--bottom'></div>
            <button>
              <FiPlus />
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default function Dashboard({ history }) {
  const headerRef = useRef(null)

  const { suggestedBusinessList, scheduledMeetingsList } = useMemo(() => {
    const sugg = [],
      sch = []
    for (let i = 0; i < 10; i++) {
      sugg.push(
        businessMeetings[
          parseInt((Math.random() * 100) % businessMeetings.length)
        ]
      )
    }
    let d
    for (let i = 0; i < 5; i++) {
      d = moment().subtract(i, 'days')
      sch[i] = { date: d.format('DD MMMM YYYY'), meetings: [] }
      for (let j = 0; j < 5; j++) {
        sch[i].meetings.push({
          ...businessMeetings[
            parseInt((Math.random() * 100) % businessMeetings.length)
          ],
          time: d.add(j, 'hour').toDate(),
        })
      }
    }
    // console.log(sch)
    return { suggestedBusinessList: sugg, scheduledMeetingsList: sch }
  }, [businessMeetings])

  const handleScroll = () => {
    if (headerRef.current) {
      if (prevScroll - window.pageYOffset < 0 && !isHeaderHidden) {
        isHeaderHidden = true
        gsap.to(headerRef.current, {
          y: -100,
          duration: 0.4,
          ease: 'power2.inOut',
        })
        // console.log('Hide!')
      } else if (prevScroll - window.pageYOffset >= 0) {
        if (isHeaderHidden) {
          isHeaderHidden = false
          gsap.to(headerRef.current, {
            y: 0,
            duration: 0.4,
            ease: 'power2.inOut',
          })
          // console.log('Show!')
        }
      }
    }
    prevScroll = window.pageYOffset
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <div className='biz-dashboard-wrapper'>
      <div className='biz-dashboard'>
        <div className='left-panel'>
          <div className='biz-greet'>
            <div className='b-avatar'>A</div>
            <p>
              {/* Hello */}
              <span>Eoraa & Co.</span>
            </p>
          </div>
          <div className='biz-time'>
            {clocks.map(c => (
              <span>
                <span>{c.of}</span>
                <span className='date-time-span'>
                  <FiCalendar />
                  <Clock timezone={c.timezone} />
                </span>
              </span>
            ))}
          </div>
          <div className='biz-float-btn'>
            <button>
              <FiPlus /> Create Meeting
            </button>
          </div>
        </div>
        <div className='main-panel'>
          <div className='biz-header' ref={headerRef}>
            <div>
              <p className='meet-sch-info'>
                <FiPhoneCall />
                You have<span>{scheduledMeetingsList.length}</span>meetings
                scheduled today
              </p>
            </div>
            <div>
              <ul className='action-list'>
                <li title='Notifications'>
                  <FiBell />
                </li>
                <li title='Call Settings'>
                  <FiSettings />
                </li>
                <li
                  title='Log out'
                  onClick={() => {
                    localStorage.removeItem('user')
                    history.push('/login')
                  }}>
                  <FiLogOut />
                </li>
              </ul>
              <span className='b-avatar'>A</span>
            </div>
          </div>
          <BizHeading
            icon={<FiLayers />}
            title='Suggestions'
            subtitle='Business suggestions for you'
          />
          <SuggestionsList list={suggestedBusinessList} />
          <BizHeading
            icon={<FiPhoneCall />}
            title='Meetings'
            subtitle='Mettings scheduled by you'
          />
          <div className='biz-meetings'>
            {scheduledMeetingsList.map(s => {
              return (
                <div className='meet-wrapper'>
                  <div className='meet-date'>
                    <span className='head-icon'>
                      <FiCalendar />
                    </span>
                    <div> {s.date} </div>
                  </div>
                  {s.meetings.map(m => {
                    let isScheduled = moment().diff(moment(m.time), 'seconds')
                    isScheduled = isScheduled <= 10 && isScheduled > -10
                    return (
                      <div className='meet-card-wrapper'>
                        <span className='head-icon'>{m.business_name[0]}</span>
                        <div className='meet-card'>
                          <div className='meet-card--head'>
                            <div className='m-h-about'>
                              <span className='m-h-about--title'>
                                {m.business_name}
                                <span></span>
                              </span>
                              <span className='m-h-about--more-info'>
                                <span>
                                  <FiMapPin /> {m.hq}
                                </span>
                                <span>
                                  <FiUser /> {m.contact_person}
                                </span>
                              </span>
                              <span className='m-h-about--memo'>
                                <FiCheckCircle />
                                <span>Demo memo</span>
                              </span>
                            </div>
                            <div className='m-h-timings'>
                              <span>{moment(m.time).format('hh:mm a')}</span>
                              <span>in {moment(m.time).fromNow(true)}</span>
                            </div>
                          </div>
                          <div className='meet-card--bottom'>
                            <span />
                            <ul>
                              <li>
                                <span className='a-ttip'>About</span>
                                <button className='meet-action--no-border'>
                                  <FiSend />
                                </button>
                              </li>
                              <li>
                                <span className='a-ttip'>Contact Manager</span>
                                <button>
                                  <FiUser />
                                </button>
                              </li>
                              <li>
                                <span className='a-ttip'>Chat</span>
                                <button>
                                  <FiMessageSquare />
                                </button>
                              </li>
                              <li>
                                <span className='a-ttip'>Start meeting</span>
                                <button
                                  className={
                                    isScheduled ? 'meet-action--active' : ''
                                  }>
                                  <FiPhone />
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
        <div className='right-panel'>
          <div className='biz-board'>
            <div className='biz-board--head'>
              <FiCalendar /> イベントカレンダー
            </div>
            <div className='biz-board--events'>
              {events.map(ev => (
                <div>
                  <span>{ev.date}</span>
                  {ev.content}
                </div>
              ))}
            </div>
          </div>
          <div className='biz-board'>
            <div className='biz-board--head'>
              <FiRss /> 最新情報
            </div>
            <div className='biz-board--announcements'>
              {announcements.map(ev => (
                <div>
                  <FiExternalLink />
                  <a href='https://yahoo.co.jp' target='_blank'>
                    {ev}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <ul className='biz-sponsor-list'>
            {new Array(5).fill('').map((_, aI) => (
              <li>
                <span>Sponsor {aI + 1}</span>
              </li>
            ))}
          </ul>
          <ul className='biz-ad-list'>
            {new Array(3).fill('').map((_, aI) => (
              <li>
                <span>Advertisement {aI + 1}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
