import React from 'react'
import './styles/main.scss'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Login from './screens/Login'
import Dashboard from './screens/Dashboard'

const PrivateRoutes = ({ children }) => {
  const user = localStorage.getItem('user')
  return (
    <>
      <Route exact path='/login' component={Login} />
      {user ? children : <Redirect to='/login' />}
    </>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoutes>
          <Route exact path={['/', '/dashboard']} component={Dashboard} />
        </PrivateRoutes>
      </Switch>
    </BrowserRouter>
  )
}

export default App
